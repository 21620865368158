export const tabLabels = [
  {
    id: "christmas",
    text: "menuCategories.christmas",
    link: "https://www.canva.com/design/DAGYyH-066s/b4rpYexuu7jVoe5gwaK0OA/view?embed",
  },
  {
    id: "dinner",
    text: "menuCategories.dinner",
    link: "https://www.canva.com/design/DAGW9fdi6xQ/DGO1zDKLA6VV1RgWpeRGJg/view?embed",
  },
  {
    id: "lunch",
    text: "menuCategories.lunch",
    link: "https://www.canva.com/design/DAGWEyjVN_o/hArx_3cDF4wmLnbNHZdQuQ/view?embed",
  },
];
